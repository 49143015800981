@import 'style/variables';
@import 'style/keyframes';

// Base link style
@mixin link {
	&:link,
	&:visited {
		font-weight: 500;
		color: inherit;
		text-transform: none;
		text-decoration: underline;
		text-decoration-color: var(--color-blue);
		text-decoration-thickness: 2px;
		border-radius: 2px;
		box-shadow: 0 0 0 2px transparent;
		transition: all 0.2s var(--ease-in-out-quart);

		#{$interact} {
			color: var(--color-white);
			background-color: var(--color-blue);
			box-shadow: 0 0 0 2px var(--color-blue);
		}
	}

	&:visited {
		text-decoration-color: var(--color-beige--desat);
	}
}

// Visually hidden
@mixin visually-hidden {
	// https://a11yproject.com/posts/how-to-hide-content/
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	white-space: nowrap; // https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
}

// Arrow down
@mixin arrow($color, $dir: 'down', $withBorder: true) {
	&::before,
	&::after {
		top: 100%;
		left: 50%;
		border: solid transparent;
		content: '';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	@if $withBorder {
		&::before {
			border-color: rgba(255, 255, 255, 0);
			border-top-color: #ffffff;
			border-width: 11px;
			margin-left: -11px;
		}
	}

	&::after {
		border-color: rgba(136, 183, 213, 0);
		border-top-color: $color;
		border-width: 8px;
		margin-left: -8px;
	}
}

// min()/max() overload: https://github.com/sass/sass/issues/2378#issuecomment-433868712
@function max($numbers...) {
	@return m#{a}x(#{$numbers});
}

@function min($numbers...) {
	@return m#{i}n(#{$numbers});
}

// px2rem
@function rem($px) {
	@return #{($px / $font-size-large)}rem;
}

// Throbber
@mixin throbber {
	content: '';
	display: inline-block;
	vertical-align: middle;
	border-radius: 50%;
	width: var(--space-m);
	height: var(--space-m);
	border: 5px solid var(--color-blue--alpha);
	border-top-color: var(--color-blue);
	animation: rotate 1s infinite ease-in-out;
}
