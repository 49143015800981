@import 'style/variables';
@import 'style/layout-helpers';
@import 'style/typography-helpers';

.root {
	a: 1;
}

.menu {
	display: flex;

	> * {
		flex: 1;
		position: relative;

		> * {
			@include heading-3;
			padding: var(--space-xs);
			text-decoration: none;
			transition: all 0.4s var(--ease-in-out-quart);
		}

		> a {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 5;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--black);

			&[aria-current] {
				&::before {
					content: '«​​​ ';
				}

				&::after {
					content: ' »';
				}
			}

			[data-page='Home'] & {
				bottom: calc(-100vh + 100%);
			}
		}
	}

	&_dummy {
		color: transparent;
		pointer-events: none;
	}

	&_factoids {
		> a {
			background-color: var(--color-blue--desat);

			#{$interact} {
				background-color: var(--color-blue);
			}
		}
	}

	&_library {
		a {
			background-color: var(--color-beige--desat);

			#{$interact} {
				background-color: var(--color-beige);
			}
		}
	}
}

.branding {
	display: none;
	align-items: center;

	> p {
		@include heading-1;
		max-width: none;
	}

	[data-page='Home'] & {
		display: flex;
		position: absolute;
		top: var(--space-m);
		left: 50%;
		transform: translateX(-50%);
		z-index: 6;
		text-align: center;
		background-color: hsla(0, 0%, 0%, 0.75);

		p {
			color: var(--color-white);
		}
	}
}

.logo {
	height: 100px;
	max-width: 180px;
	display: block;

	@media (min-width: $col-s) {
		max-width: none;
	}
}

.github {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 6;
	fill: var(--color-black);
	color: var(--color-beige--desat);

	#{$interact} {
		[class~='octo-arm'] {
			animation: octocat-wave 560ms ease-in-out;
		}
	}

	@keyframes octocat-wave {
		0%,
		100% {
			transform: rotate(0);
		}
		20%,
		60% {
			transform: rotate(-25deg);
		}
		40%,
		80% {
			transform: rotate(10deg);
		}
	}

	@media (max-width: 500px) {
		[class~='octo-arm'] {
			animation: octocat-wave 560ms ease-in-out;
		}
	}
}
