@import 'style/variables';
@import 'style/css-variables';
@import 'style/layout-helpers';
@import 'style/layout-classes';
@import 'style/misc-classes';
@import 'style/misc-helpers';
@import 'style/fonts';
@import 'style/typography';
@import 'style/tables';
@import 'style/lists';
@import 'style/chart';

// Box sizing
*,
*::before,
*::after {
	box-sizing: border-box;
}

// Focus
:focus {
	outline: 3px solid var(--color-poppy);
	outline-offset: var(--space-xxxs);
	transition: all 0.2s var(--ease-in-out-quart);
}

.js-focus-visible :focus:not(.focus-visible) {
	outline: 0;
}

::-moz-focus-inner {
	border: 0;
	outline: 0;
}

html {
	scroll-behavior: smooth;
}

// Base elements
body {
	overflow-x: hidden;
	margin: 0;
	color: var(--color-black);
	scroll-behavior: smooth;
}

#root {
	min-height: 100vh;
}

#{$heading} {
	max-width: $col-l;
}

#{$text-els} {
	color: var(--text-color);
}

p {
	a {
		@include link;
	}
}

p,
dd {
	@include space(s);
	max-width: $col-m;
}

h1 {
	margin-top: var(--space-xxxl);
}

h2 {
	margin-top: var(--space-xxl);
}

h3 {
	margin-top: var(--space-xl);
}

h4 {
	margin-top: var(--space-l);
}

::selection {
	background-color: var(--color-blue);
	color: var(--color-white);
}

pre {
	white-space: pre-wrap;

	code {
		display: block;
		padding: var(--space-xxxs) var(--space-xs);
	}
}

code {
	font-family: Consolas, monospace;
	font-size: 0.9em;
	background-color: var(--color-beige--alpha);
	border-radius: calc(var(--radius) / 2);
	text-shadow: 1px 0 0 hsla(0, 100%, 100%, 0.9);
}
