@import 'style/misc-helpers';

@mixin heading-1 {
	font-family: var(--font-title-main);
	font-weight: normal;
	font-size: rem(60px);
}

@mixin heading-2 {
	font-family: var(--font-title-main);
	font-weight: normal;
	font-size: rem(44px);
}

@mixin heading-3 {
	font-family: var(--font-title-main);
	font-weight: normal;
	font-size: rem(34px);
}

@mixin heading-4 {
	font-family: var(--font-title-small);
	font-size: rem(19px);
}

@mixin body-copy {
	font-family: var(--font-body);
	font-size: var(--font-size-base);
	line-height: 1.7;

	@media (min-width: $col-xl) {
		font-size: var(--font-size-large);
	}
}

@mixin small-copy {
	font-family: var(--font-body);
	font-size: var(--font-size-small);

	@media (min-width: $col-xl) {
		font-size: var(--font-size-base);
	}
}
