dl {
	dt {
		font-weight: 500;
	}

	dd {
		margin: 0;
		padding-left: var(--space-l);
	}
}
