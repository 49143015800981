@import 'style/misc-helpers';

// Default link style
.link {
	@include link;
}

// Visually hidden
.visually-hidden {
	@include visually-hidden;
}

// Default border/divider
.divider {
	border-bottom: 1px solid var(--color-storm);
}

// Sticky
.sticky {
	position: sticky;
	z-index: 5;
	top: 0;
}

@each $size in (xs s m l xl) {
	.sticky-#{$size} {
		position: sticky;
		z-index: 5;
		top: var(--space-#{$size});
	}
}

// Multicol text
.text-cols {
	columns: 20em;
	column-gap: var(--space-l);

	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}
}

// Hgroup
.hgroup {
	margin-bottom: var(--space-xl);

	#{$heading} {
		margin-bottom: 0;
	}

	.subtitle {
		margin-top: 0;
	}
}
