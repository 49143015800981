@font-face {
	font-family: 'Chalet-LondonNineteenSixty';
	src: url(~fonts/Chalet-LondonNineteenSixty--subset-latin-extended.woff2);
}

@font-face {
	font-family: 'Chalet-NewYorkNineteenSixty';
	src: url(~fonts/Chalet-NewYorkNineteenSixty--subset-latin-extended.woff2);
	font-weight: 700;
}

@font-face {
	font-family: 'Avenir';
	src: url(~fonts/AvenirLTStd-Book--subset-latin-extended.woff2);
}

@font-face {
	font-family: 'Avenir';
	src: url(~fonts/AvenirLTStd-Heavy--subset-latin-extended.woff2);
	font-weight: 700;
}

@font-face {
	font-family: 'Avenir';
	src: url(~fonts/AvenirLTStd-Medium--subset-latin-extended.woff2);
	font-weight: 500;
}

@font-face {
	font-family: 'Avenir';
	src: url(~fonts/AvenirLTStd-BookOblique--subset-latin-extended.woff2);
	font-style: oblique;
}
