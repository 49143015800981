@import 'style/layout-helpers';
@import 'style/misc-helpers';

.root {
	a: 1;
}

.filters {
	@include gap(s);
	display: flex;
	flex-flow: row wrap;
	align-items: flex-end;
	margin-bottom: var(--space-m);
}

.result_count {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&::after {
		content: '';
		height: 0;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		z-index: -1;
		transform: translateY(-50%);
		border-top: 1px solid var(--color-storm);
	}

	> * {
		padding: 0 var(--space-s);
		background-color: var(--color-white);
	}
}

.listing {
	dl {
		// display: grid;
		gap: var(--space-l) var(--space-xl);
		grid-auto-flow: dense;
		grid-template-columns: repeat(auto-fill, minmax(25em, 1fr));

		> div + div {
			margin-top: var(--space-l);
		}
	}

	a {
		@include link;
	}
}
