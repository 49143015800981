@import 'style/misc-helpers';
@import 'style/typography-helpers';

.root {
	display: flex;
	align-items: flex-start;

	a {
		@include link;
	}
}

.toc {
	flex: 1 0 var(--col-xxs);
	margin-right: var(--space-xxxl);
	position: sticky;
	top: calc(var(--space-xxxl) + var(--space-xl));
	bottom: 0;
	max-height: calc(100vh - (var(--space-xxxl) + var(--space-xl)));
	overflow-y: auto;

	&_title {
		@include heading-4;
		margin: 0;
	}

	ul {
		list-style: none;
		margin-left: var(--space-m);
		padding-left: 0;

		li {
			margin: var(--space-xxs) 0;
		}
	}

	> ul {
		margin-left: 0;
		padding-left: 0;
	}
}

.content {
	> :first-child {
		> :first-child {
			margin-top: 0;
		}
	}
}
