@import 'style/variables';
@import 'style/misc-helpers';

.chartist-tooltip {
	@include arrow(var(--color-blue));
	position: absolute;
	background: var(--color-blue);
	transform: translate(-50%, -130%);
	pointer-events: none;
	padding: var(--space-xxxs) var(--space-xs);
	color: var(--color-white);
	border-radius: 9999px;
	transition: 0.2s var(--ease-in-out-quart);
	transition-property: transform, opacity;
	border: 2px solid var(--color-white);

	&:not(.tooltip-show) {
		opacity: 0;
		transform: translate(-50%, -180%);
	}
}

.ct-point {
	stroke: transparent !important;
	stroke-width: 16px !important;
	pointer-events: all !important;

	#{$interact} {
		stroke: var(--color-blue--alpha50) !important;
	}
}

.ct-line {
	stroke: var(--color-blue) !important;
	stroke-width: 3px !important;
}

.ct-area {
	fill: var(--color-blue) !important;
}

.ct-grid {
	stroke: var(--color-storm--alpha50) !important;
	stroke-width: 1px !important;
	stroke-dasharray: none !important;
}

.ct-label.ct-horizontal.ct-end {
	justify-content: center !important;
	transform: translateX(-50%);
	white-space: nowrap !important;
}

.ct-label.ct-vertical.ct-start {
	transform: translateY(1ex);
	white-space: nowrap !important;
}

.ct-series {
	pointer-events: none !important;
}
