@import 'style/variables';
@import 'style/keyframes';
@import 'style/typography-helpers';

.root {
	appearance: none;
	background: transparent;
	white-space: nowrap;
	border: 0;
	padding: 0;
	cursor: pointer;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	text-decoration: none;
	font: inherit;
	font-weight: 500;
	font-size: var(--font-size-small);

	&:disabled {
		cursor: default;
	}

	#{$interact} {
		&[data-animate='icon-left'] {
			[data-component='Icon'] {
				animation: wraparound-rl 0.5s var(--ease-in-out-quart);
			}
		}

		&[data-animate='icon-right'] {
			[data-component='Icon'] {
				animation: wraparound-lr 0.5s var(--ease-in-out-quart);
			}
		}
	}

	&[data-kind='plain'] {
		.inner {
			overflow: visible;
			padding: 0;
			border: 0;
		}

		&:disabled {
			.inner {
				color: var(--color-storm);
			}
		}
	}

	&[data-kind='default'] {
		.inner {
			border-color: var(--color-blue);
		}

		&:disabled {
			.inner {
				background-color: transparent;
				border-color: var(--color-storm);
				color: var(--color-storm);
			}
		}

		&:not(:disabled) {
			#{$interact} {
				.inner {
					color: var(--color-white);
					transition: color 0.2s var(--ease-in-out-quart) 0.2s;
				}

				.effect {
					background-color: var(--color-blue);
					animation: effect 0.5s var(--ease-in-out-quart) 0.1s forwards;
				}

				[data-component='Icon'] {
					color: var(--color-white);
					transition: color 0.2s var(--ease-in-out-quart) 0.2s;
				}
			}
		}
	}

	&[data-kind='default-negative'] {
		.inner {
			color: var(--color-white);
			border-color: var(--color-white);
		}

		&:not(:disabled) {
			#{$interact} {
				.inner {
					transition: color 0.2s var(--ease-in-out-quart) 0.2s;
					color: var(--color-mallard);
				}

				.effect {
					background-color: var(--color-white);
					animation: effect 0.5s var(--ease-in-out-quart) 0.1s forwards;
				}

				[data-component='Icon'] {
					color: var(--color-white);
					transition: color 0.2s var(--ease-in-out-quart) 0.2s;
				}
			}
		}
	}

	&[data-kind='primary'] {
		.inner {
			border-color: var(--color-blue);
			background-color: var(--color-blue);
			color: var(--color-white);
		}

		&:disabled {
			.inner {
				background-color: var(--color-storm);
				border-color: var(--color-storm);
				color: var(--color-white);
			}
		}

		&:not(:disabled) {
			#{$interact} {
				.inner {
					color: var(--color-black);
					transition: color 0.2s var(--ease-in-out-quart) 0.2s;
				}

				.effect {
					background-color: var(--color-white);
					animation: effect 0.5s var(--ease-in-out-quart) 0.1s forwards;
				}

				[data-component='Icon'] {
					color: var(--color-blue);
					transition: color 0.2s var(--ease-in-out-quart) 0.2s;
				}
			}
		}

		[data-component='Icon'] {
			color: var(--color-white);
		}
	}
}

.inner {
	border-radius: 999px;
	width: 100%;
	padding: var(--space-xxxs) var(--space-s);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--color-black);
	position: relative;
	overflow: hidden;
	border: 1px solid transparent;

	[data-component='Icon'] {
		color: var(--color-blue);
	}
}

.effect {
	position: absolute;
	width: 0;
	top: -1px;
	bottom: -1px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 999px;
}

.body {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;

	// Accomodate icons
	> [data-component='Icon'] + *,
	> * + [data-component='Icon'] {
		margin-left: var(--space-xs);
	}
}

@keyframes effect {
	to {
		width: calc(100% + 2px);
	}
}
